import React from 'react'

type Props = {
  href: string
  src: string
  className?: string
}
export const ImageLink = ({ href, src, className }: Props) => {
  return (
    <div className={className}>
      <a style={ { height: 'inherit' } } href={href} target='_blank' rel='noreferrer'>
        <img className='p-1' style={ { height: 'inherit' } } src={src}/>
      </a>
    </div>
  )
}