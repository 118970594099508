import React, { lazy, Suspense } from 'react'
import { Link, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { ImageLink } from './component/ImageLink'
import { LoadingIndicator } from './component/LoadingIndicator'
import { Navbar } from './component/Navbar'

export const LINKS = {
  GUIDES: 'https://hideaway.helpshift.com/hc/en/3-hotel-hideaway/?s=game&f=fansite-way&l=en',
  WORLD_HIDEAWAY: 'https://worldhideaway.com/',
  HIDEAWAY_REPORT: 'https://hideawayreport.eu/',
  DISCORD: 'https://discord.gg/hotel-hideaway-official',
  INSTAGRAM: 'https://www.instagram.com/hideaway_official/',
  CONTENT_TIPS: '/content-tips',
  IOS: 'https://apps.apple.com/us/app/hotel-hideaway-virtual-world/id922825959',
  ANDROID: 'https://play.google.com/store/apps/details?id=com.piispanen.hotelhideaway&hl=en&gl=US',
}


const MainPage = lazy(() => import('./view/MainPage'))
const ContentTips = lazy(() => import('./view/ContentTips'))
const PrivacyPolicy = lazy(() => import('./view/PrivacyPolicy'))
const WebsitePrivacyNotice = lazy(() => import('./view/WebsitePrivacyNotice'))
const ThirdParties = lazy(() => import('./view/ThirdParties'))

function App() {

  const location = useLocation()
  const navigate = useNavigate()

  const scrollTo = (id: string) => {
    const scroll = (): boolean => {
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
        return true
      }
      return false
    }

    if (!scroll()) {
      const interval = setInterval(() => {
        if (scroll()) {
          clearInterval(interval)
        }
      }, 250)
    }
  }

  const onNewsClick = () => {
    if (location.pathname !== '/') {
      navigate('/')
    }
    scrollTo('news')
  }

  const onMediaClick = () => {
    if (location.pathname !== '/') {
      navigate('/')
    }
    scrollTo('media')
  }

  return (
    <div className='scroll-smooth overflow-x-hidden min-h-lvh flex flex-col text-primary-content'>
      <Navbar onNewsClick={onNewsClick} onMediaClick={onMediaClick} />
      <div className='flex-grow bg-primary pb-24 bg-gradient-to-b from-transparent from-[1px] via-primary via-[200px] to-plum'>
        <Routes>
          <Route path='/' element={
            <Suspense fallback={<LoadingIndicator />}>
              <MainPage />
            </Suspense>
          } />
          <Route path='/content-tips' element={
            <Suspense fallback={<LoadingIndicator />}>
              <ContentTips />
            </Suspense>
          } />
          <Route path='/privacy-policy' element={
            <Suspense fallback={<LoadingIndicator />}>
              <PrivacyPolicy />
            </Suspense>
          } />
          <Route path='/cookie-policy' element={
            <Suspense fallback={<LoadingIndicator />}>
              <WebsitePrivacyNotice />
            </Suspense>
          } />
          <Route path='/third-parties' element={
            <Suspense fallback={<LoadingIndicator />}>
              <ThirdParties />
            </Suspense>
          } />
          <Route path='*' element={<Navigate to={'/'} replace />} />
        </Routes>
      </div>
      <footer className='footer footer-center bg-secondary mt-auto'>
        <div className='flex'>
          <ImageLink href='https://play.google.com/store/apps/details?id=com.piispanen.hotelhideaway&hl=en&gl=US&pli=1' src='google-play-badge.png' className='h-20 lg:h-28' />
          <ImageLink href='https://apps.apple.com/us/app/hotel-hideaway-virtual-world/id922825959' src='app-store-badge.png' className='h-16 lg:h-24' />
        </div>
        <div className='flex'>
          <ImageLink href={LINKS.DISCORD} src='icon_discord_white.svg' className='h-12 w-12 bg-black rounded-lg' />
          <ImageLink href='https://www.tiktok.com/@hideaway.official' src='icon_tiktok_white.svg' className='h-12 w-12 bg-black rounded-lg' />
          <ImageLink href={LINKS.INSTAGRAM} src='icon_instagram_white.svg' className='h-12 w-12 bg-black rounded-lg' />
          <ImageLink href='https://twitter.com/HotelHideaway?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor' src='icon_twitter_white.svg' className='h-12 w-12 bg-black rounded-lg' />
          <ImageLink href='https://www.facebook.com/hotelhideawaythegame/' src='icon_facebook_white.svg' className='h-12 w-12 bg-black rounded-lg' />
        </div>
        <div className='text-lg text-secondary-content flex'>
          <a href='https://hideaway.helpshift.com/a/hotel-hideaway/?s=privacy&f=what-are-the-terms-of-service-of-hotel-hideaway&l=en' className='text-secondary-content hover:underline' target='_blank' rel='noreferrer'>Terms of Service</a>
          <Link to='/privacy-policy' className='text-secondary-content hover:underline'>Privacy Policy</Link>
          <Link to='/cookie-policy' className='text-secondary-content hover:underline'>Website Privacy Notice</Link>
        </div>
        <div className='text-secondary-content/75'>
          <span>DSA Single Point of Contact: <a className='hover:underline' href='mailto:contact@sulake.com'>contact@sulake.com</a></span>
        </div>
        <a href='https://www.sulake.com' className='text-secondary-content/75 pb-12 hover:underline'>
          Sulake Oy 2024
        </a>
      </footer>
    </div>
  )
}

export default App
