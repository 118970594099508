import React from 'react'

type Props = {
  showLogo?: boolean
}
export const LoadingIndicator = ({ showLogo = true }: Props) => {
  return (
    <div className='mx-auto text-primary secondary w-fit'>
      <div className='flex flex-col items-center'>
        <img className={`h-60 lg:h-80 xl:h-96 2xl:h-[525px] ${!showLogo && 'hidden'}`} src='/HideawayLogo.svg' alt='logo' loading='lazy' />
        <span className={`loading loading-dots loading-lg ml-2 ${!showLogo && 'min-h-[50vh]'}`}></span>
      </div>
    </div>
  )
}
