import React, { MouseEvent, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { LINKS } from '../App'


const isIOS = () => {
  const ua = navigator.userAgent
  if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    return true
  }
  return false
}

type Props = {
  onNewsClick: () => void,
  onMediaClick: () => void
}
export const Navbar = ({ onNewsClick, onMediaClick }: Props) => {
  const TITLES = {
    GUIDES: 'Guides',
    COMMUNITY: 'Community',
    WORLD_HIDEAWAY: 'World Hideaway',
    HIDEAWAY_REPORT: 'Hideaway Report',
    FANSITES: 'Fansites',
    DISCORD: 'Join our Discord!',
    INSTAGRAM: 'Instagram',
    CONTENT_TIPS: 'Content Tips',
    NEWS: 'News',
    FOR_MEDIA: 'For Media',
  }

  const navigate = useNavigate()
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  const openMenu = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    setMenuOpen(!menuOpen)
  }

  const selectMenuItem = (event: MouseEvent) => {
    event.stopPropagation()
    setMenuOpen(false)
  }

  const menuItemsDesktop = () => {
    const createLink = (title: string, url: string, newTab = true) => {
      return (
        <li key={title} onClick={selectMenuItem}>
          <Link to={url} target={ newTab ? '_blank' : '' } className='active:!bg-transparent'>{title}</Link>
        </li>
      )
    }

    return (
      <>
        <li>
          <Link to={LINKS.GUIDES} target='_blank' className='active:!bg-transparent hover:text-secondary'>{TITLES.GUIDES}</Link>
        </li>
        <li className='z-50'>
          <details open={menuOpen} onClick={openMenu}>
            <summary className='active:!bg-transparent hover:text-secondary'>{TITLES.COMMUNITY}</summary>
            <ul className='w-48 bg-primary-dark p-2'>
              <li>
                <span className='menu-title text-secondary-content active:!bg-transparent'>{TITLES.FANSITES}</span>
                <ul className='bg-primary-dark p-2'>
                  { createLink(TITLES.WORLD_HIDEAWAY, LINKS.WORLD_HIDEAWAY) }
                  { createLink(TITLES.HIDEAWAY_REPORT, LINKS.HIDEAWAY_REPORT) }
                </ul>
              </li>
              { createLink(TITLES.DISCORD, LINKS.DISCORD) }
              { createLink(TITLES.INSTAGRAM, LINKS.INSTAGRAM) }
              { createLink(TITLES.CONTENT_TIPS, LINKS.CONTENT_TIPS, false) }
            </ul>
          </details>
        </li>
        <li>
          <span onClick={onNewsClick} className='active:!bg-transparent hover:text-secondary'>{TITLES.NEWS}</span>
        </li>
        <li>
          <span onClick={onMediaClick} className='active:!bg-transparent hover:text-secondary'>{TITLES.FOR_MEDIA}</span>
        </li>
      </>
    )
  }

  const menuItemsMobile = () => {
    return (
      <>
        <label className='mb-5' htmlFor='mobile-menu' aria-label='close sidebar' onClick={() => navigate('/')}>
          <img className='h-20' src='/HideawayLogo.svg' alt='mainPage' />
        </label>
        <ul className='menu w-56'>
          <li>
            <Link to={LINKS.GUIDES} target='_blank'>{TITLES.GUIDES}</Link>
          </li>
          <li>
            <span className='menu-title text-secondary-content'>{TITLES.COMMUNITY}</span>
            <ul>
              <li>
                <span className='menu-title text-secondary-content'>{TITLES.FANSITES}</span>
                <ul>
                  <li><Link to={LINKS.WORLD_HIDEAWAY} target='_blank'>{TITLES.WORLD_HIDEAWAY}</Link></li>
                  <li><Link to={LINKS.HIDEAWAY_REPORT} target='_blank'>{TITLES.HIDEAWAY_REPORT}</Link></li>
                </ul>
              </li>
              <li>
                <Link to={LINKS.DISCORD} target='_blank'>{TITLES.DISCORD}</Link>
              </li>
              <li>
                <Link to={LINKS.INSTAGRAM} target='_blank'>{TITLES.INSTAGRAM}</Link>
              </li>
              <li>
                <label htmlFor='mobile-menu' aria-label='close sidebar' onClick={() => navigate(LINKS.CONTENT_TIPS)}>
                  {TITLES.CONTENT_TIPS}
                </label>
              </li>
            </ul>
          </li>
          <li>
            <label onClick={onNewsClick} htmlFor='mobile-menu' aria-label='close sidebar'>{TITLES.NEWS}</label>
          </li>
          <li>
            <label onClick={onMediaClick} htmlFor='mobile-menu' aria-label='close sidebar'>{TITLES.FOR_MEDIA}</label>
          </li>
        </ul>
        <Link to={isIOS() ? LINKS.IOS : LINKS.ANDROID} target='_blank' className='btn btn-secondary mt-5'>Play</Link>
      </>
    )
  }

  return (
    <>
      { /* DESKTOP */ }
      <div className='hidden xl:block'>
        <div className='max-h-[80px] navbar bg-primary text-secondary-content'>
          <div className='navbar-start'>
            <Link to='/'>
              <img className='h-20' src='/HideawayLogo.svg' alt='mainPage' />
            </Link>
          </div>
          <div className='navbar-end'>
            <ul className='menu menu-horizontal invisible lg:visible'>
              {menuItemsDesktop()}
            </ul>
          </div>
        </div>
      </div>
      { /* MOBILE */ }
      <div className='max-h-[80px] navbar bg-primary text-secondary-content xl:hidden'>
        <div className='drawer z-50'>
          <input id='mobile-menu' type='checkbox' className='drawer-toggle' />
          <div className='drawer-content'>
            <label htmlFor='mobile-menu' tabIndex={0} role='button' className='btn btn-ghost drawer-button'>
              <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' fill='none' viewBox='0 0 24 24' stroke='currentColor'><path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M4 6h16M4 12h8m-8 6h16' /></svg>
            </label>
          </div>
          <div className='drawer-side'>
            <label htmlFor='mobile-menu' aria-label='close sidebar' className='drawer-overlay'></label>
            <ul className='menu p-4 w-80 min-h-full bg-primary'>
              {menuItemsMobile()}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
